/* global Promise */

'use strict';

function httpService($http, $rootScope, $location, toast) {

    function handleError(err) {
        let msg = '';
        //'Une erreur est survenue lors de traitement de votre demande.';
        if (err) {
            switch (err.status) {
                case - 1:
                    msg = 'Il y a un problème de connexion au serveur.' +
                            '<br>' + 'Veuillez réessayer plus tard.';
                    break;
                case 403:
                    msg = 'Vous n\'avez pas le droit d\'accéder à ce contenu ' +
                            'ou d\'effectuer cette opération.';
                    break;
                case 404:
                    msg = 'Page ou contenu introuvable.' + '<br>' +
                            'Vous allez être redirigé.';
                    let p = $location.path(), goto = '/';
                    p = p.split('/');
                    if (p[1] === 'procedures') {
                        goto = '/procedures/' + p[2];
                        setTimeout(() => {
                            $location.path(goto);
                        }, 1500);
                    }
                    break;

            }
        }
        if (msg !== '') {
            return Promise.reject({
                type: 'HTTPService',
                message: msg
            });
        }
        return Promise.reject(err.data);
    }
    var loadingTimeout;
    function verifyReqLoading() {
        loadingTimeout = setTimeout(() => {
            if ($http.pendingRequests.length < 1) {
                clearTimeout(loadingTimeout);
                $rootScope.isLoading = false;
            } else {
                verifyReqLoading();
            }
        }, 1000);
    }

    return {
        get: (url, options = null) => {
            verifyReqLoading();
            $rootScope.isLoading = true;
            if (url.substr(-1, 1) === '/') {
                url = url.substr(0, url.length - 1);
            }
            return $http.get(url, options).then((res) => {
                return Promise.resolve(res);
            }).catch(handleError).finally(() => {
                $rootScope.isLoading = $http.pendingRequests.length > 0;
            });
        },
        post: function (url, data, options) {
            $rootScope.isLoading = true;
            if (url.substr(-1, 1) === '/') {
                url = url.substr(0, url.length - 1);
            }
            return $http.post(url, data, options).then((res) => {
                return Promise.resolve(res);
            }).catch(handleError).finally(() => {
                $rootScope.isLoading = $http.pendingRequests.length > 0;
            });
        },
        put: function (url, data, options) {
            $rootScope.isLoading = true;
            if (url.substr(-1, 1) === '/') {
                url = url.substr(0, url.length - 1);
            }
            return $http.put(url, data, options).then((res) => {
                return Promise.resolve(res);
            }).catch(handleError).finally(() => {
                $rootScope.isLoading = $http.pendingRequests.length > 0;
            });
        },
        delete: function (url) {
            $rootScope.isLoading = true;
            if (url.substr(-1, 1) === '/') {
                url = url.substr(0, url.length - 1);
            }
            return $http.delete(url).then((res) => {
                $rootScope.isLoading = false;
                return Promise.resolve(res);
            }).catch(handleError).finally(() => {
                $rootScope.isLoading = $http.pendingRequests.length > 0;
            });
        }
    };
}

angular.module('managerApp').factory('http', httpService);
